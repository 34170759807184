export enum ClientViewPrivilegesEnum {
  ClientView = 'client_view',
  BSView = 'bs_view',
  UsersView = 'users_view',
  AccountsView = 'accounts_view',
  ConnectAccountsView = 'accounts_new',
  TablesView = 'tables_view',
  DatabaseView = 'db_view',
  SubmissionsView = 'sub_view',
  StatsView = 'stats_view',
  OpportunitiesView = 'opps_view',
  GovernanceView = 'gov_view',
  GovernanceCRUD = 'gov_crud',
  GeneratorCreate = 'generator_create',
  Validator = 'validator'
}
